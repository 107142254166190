import React, { useEffect, useState } from 'react';
import { Link, graphql, navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Interweave from 'interweave';
import { GridList, GridListTile, GridListTileBar } from '@material-ui/core';
// components
import homePageStyle from '../assets/jss/pale-legs-style/pages/homepageStyle';
import SEO from '../components/Seo/seo';
import Parallax from '../components/Parallax/Parallax';
import GridItem from '../components/Grid/GridItem';
import GridContainer from '../components/Grid/GridContainer';
import Card from '../components/Card/Card';
import Button from '../components/CustomButtons/Button';
import FormatDate from '../Functions/FormateDate';
import BlogDivider from '../components/Dividers/Divider';
import BlogCard from '../components/BlogCard/BlogCard';
import isMobile from '../Functions/isMobile';

const useStyles = makeStyles(homePageStyle);

const IndexPage = (props) => {
    const classes = useStyles(props);
    const checkDate = Date.now();
    const blogPosts = props.data.allBlogs.edges.reduce((a, v) => {
        if (a.length < 4) {
            const { date, publish } = v.node;
            if (date < checkDate && publish) {
                a.push(v.node);
            }
        }
        return a;
    }, []);

    const [collNum, setCollNum] = useState(3);
    const pageData = props.data.allSiteData.nodes[0].content;

    const firstBlog = ({
        content, id, title, images, date,
    }) => (
        <Card style={{ width: '95%' }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6} container justify="center" alignItems="center">
                    <img
                        alt="blog-image"
                        style={{
                            padding: 15,
                            maxWidth: '100%',
                            width: 'auto',
                            height: 'auto',
                            maxHeight: 400,
                            borderRadius: 5,
                        }}
                        src={images[0]}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} container alignItems="center">
                    <div style={{ marginBottom: 50 }}>
                        <GridContainer container alignItems="center" style={{ margin: '2%' }}>
                            <h3>{title}</h3>
                        </GridContainer>
                        <div style={{ margin: '2%' }}>
                            <Interweave
                                content={`${content.substring(0, 600)}...`}
                            />
                            <p style={{ fontSize: 12, color: '#3C4858' }}>
                                Written:
                                {FormatDate(date)}
                            </p>
                        </div>
                        <Button onClick={() => navigate(`/blog/posts/${id}`)} size="sm" round>
                            Read More
                        </Button>
                    </div>
                </GridItem>
            </GridContainer>
        </Card>
    );

    useEffect(() => {
        if (window.innerWidth < 980) {
            setCollNum(1.5);
        }
    }, []);

    const renderNextBlogs = (posts) => (
        <GridList className={classes.GridList} cols={collNum} cellHeight={isMobile() ? 350 : 450}>
            {posts.map((post) => {
                const {
                    title, id, images, date,
                } = post;
                return (
                    <GridListTile
                        key={id}
                        classes={{
                            tile: classes.gridListTile,
                        }}
                    >
                        <BlogCard
                            title={title}
                            onClick={() => navigate(`/blog/posts/${id}`)}
                            image={images[0]}
                            date={date}
                        />

                    </GridListTile>
                );
            })}
        </GridList>
    )

    /*
    return (
      <GridContainer >
        {
          posts.map(post=>{
            const {title,id,images,date}=post;
            return (
              <GridItem xs={12} sm={12} md={4} key={id} container justify={'center'} >
                <BlogCard
                  title={title}
                  onClick={()=>navigate(`/blog/posts/${id}`)}
                  image={images[0]}
                  date={date}
                />
              </GridItem>

            )
          })
        }
      </GridContainer>
    )

     */
    ;

    return (
        <div style={{ marginTop: 70 }}>
            <SEO title="Home" />
            <Parallax small filter={pageData.filter} image={pageData.bannerImage}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem container justify="center" alignItems="center">
                            <h1>{pageData.bannerTitle}</h1>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classes.main}>
                <GridContainer justify="center">
                    {firstBlog(blogPosts[0])}
                </GridContainer>
                <BlogDivider />
                {renderNextBlogs([blogPosts[1], blogPosts[2], blogPosts[3]])}

            </div>
        </div>
    );
};

export const query = graphql`
    query HomeBlogQuery {
        allBlogs{
            edges {
                node {
                    id
                    title
                    date
                    categories
                    content
                    allowComments
                    images
                    publish
                }
            }
        }
        allSiteData(filter: {title: {eq: "homePage"}}) {
            nodes {
                title
                content {
                    bannerImage
                    bannerTitle
                    content
                    description
                    title
                    filter
                }
            }
        }
    }
  
`;

export default IndexPage;
